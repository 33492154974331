import { Injectable } from '@angular/core';

import { Observable, Subject, Subscriber, combineLatest, first, map, merge } from 'rxjs';

import { ApImChannelService, ApIotChannelService, ApRttChannelService, ApRttGhaleChannelService, ApWebrtcChannelService } from '@ghale/ngx-ap';
import { WebRtcService } from '@ghale/ngx-webrtc-client';

import { BaseEvent } from '@ghale-ap/app/definitions/base-event';
import { CallData } from '@ghale-ap/app/definitions/call-data';
import { CordovaWebSocket } from '@ghale-ap/app/definitions/cordova-websocket';
import { ErrorCode } from '@ghale-ap/app/definitions/error-code';
import { WebsocketEventMap } from '@ghale-ap/app/definitions/websocket-event-map';
import { CapabilityService } from '@ghale-ap/app/services/capability.service';
import { WebSocketService } from '@ghale-ap/app/services/websocket.service';
import { AuthenticationService } from './authentication.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ApService {

  get chatOpen (): boolean {
    return this._chatOpen;
  }

  set chatOpen (value: boolean) {
    this._chatOpen = value;
  }

  get iotOpen (): boolean {
    return this._iotOpen;
  }

  set iotOpen (value: boolean) {
    this._iotOpen = value;
  }

  get connected (): boolean {
    return this.socket && this.socket.readyState === WebSocket.OPEN;
  }

  get deliverySuccess (): Promise<boolean> {
    return this.storage.get('deliverySuccess');
  }

  get rttOpen (): boolean {
    return this._rttOpen;
  }

  set rttOpen (value: boolean) {
    this._rttOpen = value;
  }

  get rttGhaleOpen (): boolean {
    return this._rttGhaleOpen;
  }

  set rttGhaleOpen (value: boolean) {
    this._rttGhaleOpen = value;
  }

  get webrtcOpen (): boolean {
    return this._webrtcOpen;
  }

  set webrtcOpen (value: boolean) {
    this._webrtcOpen = value;
  }

  get sessionId (): Promise<string> {
    return this.storage.get('sessionId');
  }

  private _chatOpen: boolean;

  private _iotOpen: boolean;

  private _rttOpen: boolean;

  private _rttGhaleOpen: boolean;

  private cleanClose = false;

  private readonly error = new Subject<ErrorCode>();

  private readonly reconnect = new Subject<void>();

  private retries: number;

  private socket: WebSocket | CordovaWebSocket;

  private _webrtcOpen: boolean;

  constructor (
    private readonly authenticationService: AuthenticationService,
    private readonly webSocketService: WebSocketService,
    private readonly capabilityService: CapabilityService,
    private readonly apImChannelService: ApImChannelService,
    private readonly apIotChannelService: ApIotChannelService,
    private readonly apRttChannelService: ApRttChannelService,
    private readonly apRttGhaleChannelService: ApRttGhaleChannelService,
    private readonly apWebrtcChannelService: ApWebrtcChannelService,
    private readonly webRtcService: WebRtcService,
    private readonly storage: StorageService
  ) { }

  clearSession (): void {
    this.setDeliverySuccess(false);
    this.setSessionId(null);
    this.chatOpen = false;
    this.iotOpen = false;
    this.rttOpen = false;
    this.rttGhaleOpen = false;
    this.webrtcOpen = false;
    this.capabilityService.setCapabilities(null);
    this.capabilityService.setWebrtcNativeProperties(null, null);
    this.capabilityService.setEmbeddedWebrtcProperties(null, null);
    this.capabilityService.setSharedSpaceProperties(null, null);
    this.capabilityService.setEiddProperties(null, null);
    this.capabilityService.setSipProperties(null);
    this.capabilityService.setSipsProperties(null);
  }

  closeChat (): void {
    this.chatOpen = false;
    this.apImChannelService.exit();
  }

  closeIot (): void {
    this.iotOpen = false;
    this.apIotChannelService.exit();
  }

  closeRtt (): void {
    this.rttOpen = false;
    this.apRttChannelService.exit();
  }

  closeRttGhale (): void {
    this.rttGhaleOpen = false;
    this.apRttGhaleChannelService.exit();
  }

  closeWebrtc (): void {
    this.webrtcOpen = false;
    this.webRtcService.exit(true);
  }

  async connect (apUrl: string, token: string, callData: CallData): Promise<void> {
    this.cleanClose = false;
    this.retries = 3;

    return this.startConnection(apUrl, token, callData);
  }

  connectionError (): Observable<ErrorCode> {
    return this.error.asObservable();
  }

  disconnect (onClosePost?: boolean): void {
    if (this.connected) {
      this.cleanClose = true;
      this.clearSession();
      if (!onClosePost) {
        this.emit('close', { });
      }
      this.socket.close();
    }
  }

  emit <K extends keyof WebsocketEventMap> (type: K, event: WebsocketEventMap[K]): void {
    if (this.connected) {
      console.log('Sending ws message: ',
        Object.assign<WebsocketEventMap[K], BaseEvent>(event, { type: type.toUpperCase() }));
      this.socket.send(JSON.stringify(event));
    }
  }

  on <K extends keyof WebsocketEventMap> (type: K, handler: (event: WebsocketEventMap[K]) => void): void {
    if (this.connected) {
      this.socket.addEventListener('message', (event: any) => {
        const json: BaseEvent & WebsocketEventMap[K] = JSON.parse(event.data);
        if (json.type === type.toUpperCase()) {
          console.log('Received ws message: ', json);
          handler(json);
        }
      });
    }
  }

  openChat (): Observable<void> {
    return this.apImChannelService.listen(this.socket as WebSocket);
  }

  openIot (): Observable<void> {
    return this.apIotChannelService.listen(this.socket as WebSocket);
  }

  openRtt (): Observable<void> {
    return this.apRttChannelService.listen(this.socket as WebSocket);
  }

  openRttGhale (): Observable<void> {
    return this.apRttGhaleChannelService.listen(this.socket as WebSocket);
  }

  openWebrtc (): Observable<void> {
    const apWebrtcChannelServiceObservable = this.apWebrtcChannelService.listen(this.socket as WebSocket);
    const webrtcServiceObservable = this.webRtcService.listen(this.socket as WebSocket);

    return combineLatest([apWebrtcChannelServiceObservable, webrtcServiceObservable]).pipe(
      first(), // Take the first emission only
      map(() => {
        // Map the result to void
        return;
      })
    );
  }

  reconnection (): Observable<void> {
    return this.reconnect.asObservable();
  }

  setDeliverySuccess (value: boolean): void {
    this.storage.set('deliverySuccess', value);
  }

  setSessionId (value: string): void {
    this.storage.set('sessionId', value);
  }

  private _connect (apUrl: string, token: string): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      try {
        console.log('Opening websocket');
        this.socket = this.webSocketService.createWebSocket(apUrl, token);

        this.socket.addEventListener('close', (e) => {
          console.log('Close', e);
          if (e.wasClean) {
            subscriber.complete();
          } else {
            subscriber.error(e);
          }
        });

        this.socket.addEventListener('error', (e) => {
          console.log('Error', e);
          subscriber.error(e);
        });

        this.socket.addEventListener('open', () => {
          console.log('Opened');
          subscriber.next();
        });
      } catch (e) {
        subscriber.error(e);
        subscriber.complete();
      }
    });
  }

  private async continue (callData: CallData, sessionId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.on('reestablished', (reestablisedEvent) => {
        if (reestablisedEvent.result === 'SUCCESS') {
          this.reconnect.next();
          resolve();
        } else {
          console.error(reestablisedEvent);
          reject(ErrorCode.REESTABLISHED_FAIL);
        }
      });

      this.on('error', (err) => {
        console.error(err);
        reject(ErrorCode.REESTABLISHED_FAIL);
      });

      this.emit('continue', {
        callData,
        sessionId
      });
    });
  }

  private async delivery (): Promise<void> {
    return new Promise((resolve, reject) => {
      this.on('delivery_success', (deliverySuccessEvent) => {
        this.capabilityService.setCapabilities(deliverySuccessEvent.capabilities);
        resolve();
      });
      this.on('delivery_fail', (deliveryFailEvent) => {
        console.error(deliveryFailEvent);
        reject(ErrorCode.DELIVERY_FAIL);
      });
      this.on('error', (err) => {
        console.error(err);
        reject(ErrorCode.DELIVERY_FAIL);
      });
    });
  }

  private async getToken (): Promise<string> {
    const token = await this.authenticationService.getToken;
    if (!token || token.expiration < new Date().getTime()) {
      return new Promise((resolve, reject) => {
        this.authenticationService.generateToken().subscribe((t) => {
          this.authenticationService.setToken(t);
          resolve(t.token);
        }, (error) => reject(error));
      });
    } else {
      return token.token;
    }
  }

  private onCapabilities (): void {
    this.onIm();
    this.onIot();
    this.onRtt();
    this.onRttGhale();
    this.onWebrtc();
  }

  private onIm (): void {
    this.on('im', (event) => {
      if (event.content.type === 'OPEN') {
        this.chatOpen = true;
      }
      if (event.content.type === 'CLOSE' || event.content.type === 'ERROR') {
        this.chatOpen = false;
      }
    });
  }

  private onIot (): void {
    this.on('iot/pemea', (event) => {
      if (event.content.type === 'OPEN') {
        this.iotOpen = true;
      }
      if (event.content.type === 'CLOSE' || event.content.type === 'ERROR') {
        this.iotOpen = false;
      }
    });
  }

  private onRtt (): void {
    this.on('rtt/pemea', (event) => {
      if (event.content.type === 'OPEN') {
        this.rttOpen = true;
      }
      if (event.content.type === 'CLOSE' || event.content.type === 'ERROR') {
        this.rttOpen = false;
      }
    });
  }

  private onRttGhale (): void {
    this.on('rtt/ghale', (event) => {
      if (event.content.type === 'OPEN') {
        this.rttGhaleOpen = true;
      }
      if (event.content.type === 'CLOSE' || event.content.type === 'ERROR') {
        this.rttGhaleOpen = false;
      }
    });
  }

  private onWebrtc (): void {
    this.on('audio_video/pemea', (event) => {
      if (event.content.type === 'OPEN') {
        this.webrtcOpen = true;
      }
      if (event.content.type === 'CLOSE' || event.content.type === 'ERROR') {
        this.webrtcOpen = false;
      }
    });
  }

  private async setup (callData: CallData): Promise<string> {
    return new Promise((resolve, reject) => {
      this.on('established', (establisedEvent) => {
        if (establisedEvent.result === 'SUCCESS') {
          resolve(establisedEvent.sessionId);
        } else {
          console.error(establisedEvent);
          reject(ErrorCode.ESTABLISHED_FAIL);
        }
      });

      this.on('error', (err) => {
        console.error(err);
        reject(ErrorCode.ESTABLISHED_FAIL);
      });

      this.emit('setup', {
        callData
      });
    });
  }

  private async startConnection (apUrl: string, token: string, callData: CallData): Promise<void> {
    return new Promise((resolve, reject) => {
      const timeout = window.setTimeout(() => reject(ErrorCode.TIMEOUT), 20000);
      this._connect(`${apUrl.replace('http', 'ws')}/mobile/ws/call`, token)
        .subscribe(() => {
          this.sessionId.then((sessionId) => {
            if (!sessionId) {
              this.setup(callData)
                .then((newSessionId) => {
                  this.setSessionId(newSessionId);
                  this.delivery()
                    .then(() => {
                      this.setDeliverySuccess(true);
                      this.onCapabilities();
                      window.clearTimeout(timeout);
                      resolve();
                    })
                    .catch((err) => reject(err));
                })
                .catch((err) => reject(err));
            } else {
              this.deliverySuccess.then((deliverySuccess) => {
                if (!deliverySuccess) {
                  this.continue(callData, sessionId)
                    .then(() => {
                      this.delivery()
                        .then(() => {
                          this.setDeliverySuccess(true);
                          this.onCapabilities();
                          window.clearTimeout(timeout);
                          resolve();
                        })
                        .catch((err) => reject(err));
                    })
                    .catch((err) => reject(err));
                } else {
                  this.continue(callData, sessionId)
                    .then(() => {
                      this.onCapabilities();
                      window.clearTimeout(timeout);
                      resolve();
                    })
                    .catch((err) => reject(err));
                }
              });
            }
          });
        },
          (err: CloseEvent) => {
            console.error('Websocket with AP closed: ', err);
            if (this.cleanClose) {
              this.error.next(ErrorCode.CLOSE);
              reject(ErrorCode.CLOSE);
            } else {
              if (this.retries-- > 0) {
                window.setTimeout(async () => {

                  if (await this.authenticationService.getActive) {
                    token = await this.getToken();
                  }

                  this.startConnection(apUrl, token, callData)
                    .then(() => this.retries = 3)
                    .catch((e) => reject(e));
                }, 1000);
              } else {
                this.error.next(ErrorCode.CONNECTION_DROPPED);
                reject(err);
              }
            }
          }
        );
    });
  }
}
